import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lang from '../../constants/lang';
import { URL_PATHNAMES } from '../../constants/urlPath';

const ReportSidebarItems = ({ handleClick }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const {pathname} = location
  const { reportedUsers, reportedComments, reportedPosts } = lang;
  const { PATH_REPORTED_USERS, PATH_REPORTED_COMMENTS, PATH_REPORTED_POSTS} =
    URL_PATHNAMES;

  const menus = [
    {
      title: reportedUsers,
      link: PATH_REPORTED_USERS
    },
    {
      title: reportedComments,
      link: PATH_REPORTED_COMMENTS
    },
    {
      title: reportedPosts,
      link: PATH_REPORTED_POSTS
    },
  ];

  const renderItems = () => {
    if (menus) {
      return menus.map(({ title, link }) => (
        <div
          key={link}
          className={`${
            pathname === link ? 'reportSidebarItem-active' : 'reportSidebarItem'
          }`}
          onClick={() => {
            navigate(link),
            handleClick();
          }}>

          <div className={`${
            pathname === link ? 'reportSidebarItem-text' : ''
          }`}>{title}</div>
        </div>
      ));
    }
    return;
  };

  return <>{renderItems()}</>;
};

export default ReportSidebarItems;
