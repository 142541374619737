export default function MainLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="38" viewBox="0 0 140 38" fill="none">
    <path d="M51.9798 22.2373C55.4171 22.2373 57.6451 23.4217 57.6451 26.4966C57.6451 29.74 54.9939 30.8607 51.8124 30.8607H49.7519V35.6621C49.7519 36.9102 48.8588 37.138 48.417 37.138C47.9704 37.138 47.082 36.9102 47.082 35.6621V23.7998C47.082 22.7384 47.5937 22.2419 48.6728 22.2419H51.9798V22.2373ZM49.7519 28.6559H51.617C53.3566 28.6559 54.9474 28.2413 54.9474 26.4966C54.9474 24.7519 53.3566 24.4376 51.7008 24.4376H49.7472V28.6559H49.7519Z" fill="#0071BC"/>
    <path d="M68.045 35.8257C68.045 36.9463 67.217 37.1331 66.8356 37.1331C66.4542 37.1331 65.6263 36.9463 65.6263 35.8257V35.3474H65.5844C65.1379 36.345 63.9286 37.2378 62.4216 37.2378C59.7703 37.2378 58.668 35.2426 58.668 33.2063V28.1772C58.668 26.9928 59.5192 26.7832 59.9424 26.7832C60.3657 26.7832 61.2169 26.9928 61.2169 28.1772V32.5002C61.2169 33.6846 61.4913 35.1606 63.1286 35.1606C64.7425 35.1606 65.5054 33.7894 65.5054 32.4592V28.1772C65.5054 26.9928 66.3519 26.7832 66.7798 26.7832C67.2031 26.7832 68.0543 26.9928 68.0543 28.1772V35.8257H68.045Z" fill="#0071BC"/>
    <path d="M72.2471 37.1334C71.8238 37.1334 70.9727 36.9238 70.9727 35.7394V28.1137C70.9727 26.993 71.8006 26.7835 72.182 26.7835C72.5867 26.7835 73.4146 26.993 73.4146 28.1137V28.633H73.4564C73.8611 27.5533 74.8797 26.6787 76.1077 26.6787C76.8705 26.6787 77.424 27.0523 77.424 27.7811C77.424 28.4234 77.0193 28.9837 76.0426 28.9837C74.4286 28.9837 73.5169 30.1681 73.5169 31.8309V35.7394C73.5216 36.9284 72.675 37.1334 72.2471 37.1334Z" fill="#0071BC"/>
    <path d="M80.288 36.9511C79.2042 36.9511 78.6973 36.45 78.6973 35.3931V23.7996C78.6973 22.7382 79.2089 22.2417 80.288 22.2417H84.023C87.9487 22.2417 92.3209 24.0684 92.3209 29.5987C92.3209 34.7508 87.9487 36.9556 84.023 36.9556H80.288V36.9511ZM81.3718 34.6643H83.5997C86.3579 34.6643 89.4557 33.5619 89.4557 29.5941C89.4557 25.3758 86.3579 24.483 83.5997 24.483H81.3718V34.6643Z" fill="#0071BC"/>
    <path d="M95.9307 37.1334C95.5074 37.1334 94.6562 36.9238 94.6562 35.7394V28.1137C94.6562 26.993 95.4842 26.7835 95.8656 26.7835C96.2702 26.7835 97.0982 26.993 97.0982 28.1137V28.633H97.14C97.5447 27.5533 98.5633 26.6787 99.7913 26.6787C100.554 26.6787 101.108 27.0523 101.108 27.7811C101.108 28.4234 100.703 28.9837 99.7262 28.9837C98.1122 28.9837 97.2005 30.1681 97.2005 31.8309V35.7394C97.2005 36.9284 96.354 37.1334 95.9307 37.1334Z" fill="#0071BC"/>
    <path d="M103.544 25.0842C102.609 25.0842 101.93 24.3781 101.93 23.59C101.93 22.802 102.609 22.0913 103.544 22.0913C104.479 22.0913 105.2 22.7382 105.2 23.59C105.2 24.4373 104.479 25.0842 103.544 25.0842ZM103.567 37.1332C103.144 37.1332 102.292 36.9237 102.292 35.7393V28.1728C102.292 26.9884 103.144 26.7788 103.567 26.7788C103.99 26.7788 104.841 26.9884 104.841 28.1728V35.7393C104.841 36.9282 103.99 37.1332 103.567 37.1332Z" fill="#0071BC"/>
    <path d="M111.758 37.1331C111.144 37.1331 110.4 36.8825 109.912 35.7619L106.814 28.6737C106.688 28.3821 106.646 28.1544 106.646 27.9676C106.646 27.2843 107.195 26.7832 107.94 26.7832C108.34 26.7832 108.851 26.929 109.191 27.8036L111.735 34.245H111.777L114.386 27.7854C114.726 26.9335 115.233 26.7878 115.619 26.7878C116.363 26.7878 116.87 27.2889 116.87 27.9312C116.87 28.0587 116.847 28.3047 116.698 28.6373L113.6 35.7665C113.116 36.8871 112.354 37.1331 111.758 37.1331Z" fill="#0071BC"/>
    <path d="M120.521 32.7511C120.628 34.1632 121.944 35.2019 123.428 35.2019C124.488 35.2019 125.126 34.8693 125.763 34.35C126.126 34.0585 126.358 33.9947 126.633 33.9947C127.205 33.9947 127.651 34.3864 127.651 34.9923C127.651 35.2019 127.568 35.5708 127.228 35.8852C126.233 36.819 125.084 37.2336 123.451 37.2336C120.312 37.2336 117.977 35.2611 117.977 32.0176C117.977 28.797 120.288 26.6787 123.367 26.6787C125.828 26.6787 127.823 28.0499 128.228 30.979C128.247 31.1886 128.27 31.3343 128.27 31.4983C128.27 32.373 127.781 32.7465 126.935 32.7465H120.521V32.7511ZM125.744 30.9608C125.744 29.6716 125 28.551 123.344 28.551C121.837 28.551 120.628 29.5668 120.521 30.9608H125.744Z" fill="#0071BC"/>
    <path d="M130.623 28.0953C130.623 26.9747 131.451 26.7879 131.832 26.7879C132.213 26.7879 133.041 26.9747 133.041 28.0953V28.5736H133.083C133.53 27.576 134.739 26.6831 136.246 26.6831C138.897 26.6831 140 28.6784 140 30.7146V35.7438C140 36.9282 139.153 37.1377 138.725 37.1377C138.302 37.1377 137.451 36.9282 137.451 35.7438V31.4207C137.451 30.2363 137.176 28.7604 135.539 28.7604C133.93 28.7604 133.162 30.1315 133.162 31.4617V35.7438C133.162 36.9282 132.316 37.1377 131.888 37.1377C131.464 37.1377 130.613 36.9282 130.613 35.7438V28.0953H130.623Z" fill="#0071BC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.62864 30.0812C5.00997 26.9881 2.87502 23.2846 1.53545 19.3442C0.135415 15.2215 -0.39483 10.8347 0.312166 6.62093C0.772643 3.87858 2.14477 0.580471 5.58672 2.9265C7.20537 4.02891 8.73099 6.20183 9.95893 7.55479C11.3683 9.10818 13.1032 10.3974 15.1637 11.4223C18.5313 13.0987 18.8336 15.3536 17.6987 18.6745C16.5033 22.1594 15.2521 25.6352 14.066 29.1337C12.7032 33.147 10.1636 33.0787 7.62864 30.0812Z" fill="url(#paint0_linear_1093_21418)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.76237 28.4141C6.43675 24.1366 6.31582 19.6587 7.30655 15.4267C8.33448 11.0399 10.5531 6.91266 13.8695 3.54167C17.958 -0.617412 24.2558 -2.19358 26.0419 4.89462C26.4466 6.51634 27.0094 8.09707 27.7164 9.6049C28.5257 11.3451 29.5304 12.9987 30.7118 14.5338C33.5398 18.2146 31.656 20.0504 28.3071 22.3054C23.493 25.5488 17.8557 29.0291 12.7113 31.6849C10.5578 32.801 9.09264 32.7144 7.76237 28.4141Z" fill="url(#paint1_linear_1093_21418)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.55009 27.7903C7.9547 28.9291 8.1547 30.4643 9.41985 31.7079C13.2804 35.4934 22.4853 37.229 27.3087 37.2473C31.1739 37.2609 34.5275 36.072 35.8531 32.1999C36.7183 29.6625 37.0811 26.7516 36.9322 23.4808C36.7555 19.627 35.4764 18.0599 31.3972 19.3263C26.5459 20.8296 11.5129 26.3872 9.55009 27.7903Z" fill="url(#paint2_linear_1093_21418)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3621 37.6163C14.7063 37.6618 14.9295 37.4978 14.9807 37.3247C15.0458 37.1152 14.8877 36.8327 14.4877 36.6779C12.5574 35.949 10.7434 34.8739 9.02704 33.6667C7.51537 32.6099 6.15255 31.4665 4.7153 30.3322C4.33389 30.0315 3.38968 29.3163 3.07804 30.0088C2.89199 30.4278 2.90595 30.8561 3.05944 31.2843C3.30131 31.9539 3.89202 32.619 4.63158 33.2294C6.82233 35.0561 11.5108 37.2564 14.3621 37.6163Z" fill="#0071BC"/>
    <defs>
      <linearGradient id="paint0_linear_1093_21418" x1="1.42154" y1="20.4926" x2="16.5582" y2="11.5696" gradientUnits="userSpaceOnUse">
        <stop offset="0.3743" stopColor="#75B0CA"/>
        <stop offset="0.5402" stopColor="#60A1C8"/>
        <stop offset="0.7514" stopColor="#408AC6"/>
        <stop offset="0.8771" stopColor="#1282C3"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1093_21418" x1="3.92455" y1="22.6373" x2="28.4465" y2="8.18154" gradientUnits="userSpaceOnUse">
        <stop offset="0.2116" stopColor="#9E1F63"/>
        <stop offset="0.5274" stopColor="#D91C5C"/>
        <stop offset="0.9892" stopColor="#ED4663"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1093_21418" x1="12.319" y1="36.9109" x2="37.3617" y2="22.1481" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDC60B"/>
        <stop offset="0.4481" stopColor="#F28F3F"/>
        <stop offset="0.7156" stopColor="#ED693C"/>
        <stop offset="1" stopColor="#E83E39"/>
      </linearGradient>
    </defs>
  </svg>
  );
}
