export const URL_PATHNAMES = {
  PATH_LOGIN: '/',
  PATH_HOME: '/dashboard',
  // PATH_ACTIVE_USER: '/active-user',
  // PATH_DEATH_REQUEST: '/death-request',
  PATH_STORY_CATEGORIES: '/stories-category',
  PATH_CUSTOM_NOTIFICATION: '/notifications',
  PATH_REPORTED: '/reported',
  PATH_REPORTED_USERS: '/reported-users',
  PATH_REPORTED_COMMENTS: '/reported-comments',
  PATH_REPORTED_POSTS: '/reported-posts',
  PATH_DEAD_USER: '/dead-user',
  PATH_REPORTED_TIME_CAPSULE : '/reported-time-capsule',
  PATH_VERSION_UPDATE : '/version-update',
};
