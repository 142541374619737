import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_PATHNAMES } from '../../constants/urlPath';
import MainLogo from '../svg/MainLogo';
import SidebarItems from './SidebarItems';
import './style.scss';

const Sidebar = ({ tabActive }) => {
  const navigate = useNavigate();
  const { PATH_HOME } = URL_PATHNAMES;
  return (
    <div className="sidebar">
      <div className="brandIcon curser-pointer">
        <div onClick={() => navigate(PATH_HOME)}>
          <MainLogo />
        </div>
      </div>
      <SidebarItems tabActive={tabActive} />
    </div>
  );
};

export default Sidebar;
