import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication/index';
import loader from './Loader/index';
import reported from './Reported/index';

const rootReducer = combineReducers({
  authentication,
  loader,
  reported
});

export default rootReducer;
