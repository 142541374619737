const REGEX = {
  EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
  PASSWORD_PATTERN:
    /(?=[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,}).*$/, // Passwords must be Include at least 1 lowercase letter, 1 capital letter, 1 number,  1 special character => !@#$%^&*
  USERNAME_PATTERN: /^(?!_.)[a-zA-Z0-9](?!.*?[_.]{2})+(.*[a-zA-Z0-9])?$/
};

const urls = {
  dev : 'https://dev.purdriven.com/',
  uat: 'https://uat.purdriven.com/',
}

export { REGEX, urls };
