import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrimaryButton = styled(Button)({
  background: 'linear-gradient(61deg, #9E1F63 17.87%, #ED4663 81.44%)',
  borderRadius: '35px',
  height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontFamily: 'Poppins',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#FFFFFF',
  boxShadow: 'none',
  border: 'none'
});

export const SecondaryButton = styled(Button)({
  background: '#FFFFFF',
  borderRadius: '35px',
  height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontFamily: 'Poppins',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#1A1A1A',
  boxShadow: 'none'
});

export const FollowButton = styled(Button)({
  background: '#EA9E34',
  borderRadius: '35px',
  height: '30px',
  textTransform: 'none',
  width: '100px',
  fontWeight: '500',
  fontFamily: 'Poppins',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#FFFFFF',
  boxShadow: 'none',
  border: 'none',
  '&:hover': {
    background: '#EA9E34'
  }
});

export const DownloadButton = styled(Button)({
  background: 'linear-gradient(61deg, #9E1F63 17.87%, #ED4663 81.44%)',
  borderRadius: '4',
  height: '32px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontFamily: 'Poppins',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#FFFFFF',
  boxShadow: 'none',
  border: 'none'
});
