const lang = {
  dashboard: 'Dashboard',
  reports: 'Reports',
  activeUsers: 'Active Users',
  deathRequests: 'Death Requests',
  storiesCategories: 'Stories Categories',
  customNotifications: 'Custom Notifications',
  reported: 'Reported',
  logout: 'Logout',
  reportedUsers: 'Reported Users',
  reportedComments: 'Reported Comments',
  reportedPosts: 'Reported Posts',
  welcomeText: 'Welcome To The',
  adminPanel: 'Admin Panel!',
  pleaseLogin: 'Please log in to your account',
  forgotPassword: 'Forgot Password?',
  login: 'Log in',
  deadUsers: 'Dead Users',
  customNotification : 'Custom Notification',
  notificationTitle: 'Notification Title',
  notificationDescription: 'Notification Description',
  send: 'Send',
  reportedTimecapsule: 'Reported Timecapsules',
  versioUpdate: 'Version Update'
};
export default lang;
