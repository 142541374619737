import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { URL_PATHNAMES } from './constants/urlPath';
import PublicRoute from './PublicRoute';
import AuthRoute from './AuthRoute';
// import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ActiveUser from './pages/ActiveUser';
import ReportedUser from './pages/Reported/ReportedUser';
// import DeathUserRequest from './pages/DeathUser';
// import StoryCategory from './pages/StoryCategory';
// import DeadUser from './pages/ActiveUser/deadUser';
import ReportedComments from './pages/Reported/ReportedComments';
import ReportedPosts from './pages/Reported/ReportedPosts';
import Loader from './components/Loader';
import CustomNotification from './pages/CustomNotifications';
// import ReportedTimeCapsule from './pages/Reported/ReportedTimecapsule';
// import VersionUpdate from './pages/VersionUpdate/index';

function App(props) {
  const { loader: { isLoading = false } = {} } = props;
  const {
    PATH_HOME,
    PATH_LOGIN,
    // PATH_ACTIVE_USER,
    // PATH_DEATH_REQUEST,
    // PATH_STORY_CATEGORIES,
    PATH_CUSTOM_NOTIFICATION,
    PATH_REPORTED,
    PATH_REPORTED_USERS,
    PATH_REPORTED_COMMENTS,
    PATH_REPORTED_POSTS,
    // PATH_DEAD_USER,
    // PATH_REPORTED_TIME_CAPSULE,
    // PATH_VERSION_UPDATE
  } = URL_PATHNAMES;
  return (
    <>
      {isLoading ? <Loader is_loading={isLoading} /> : null}
      <Routes>
        <Route
          exact
          path={PATH_LOGIN}
          element={<PublicRoute Component={Login} />}
        />
        {/* <Route
          exact
          path={PATH_HOME}
          element={<AuthRoute Component={Dashboard} />}
        /> */}
        <Route
          exact
          path={PATH_HOME}
          element={<AuthRoute Component={ActiveUser} />}
        />
        {/* <Route
          exact
          path={PATH_DEAD_USER}
          element={<AuthRoute Component={DeadUser} />}
        />
        <Route
          exact
          path={PATH_DEATH_REQUEST}
          element={<AuthRoute Component={DeathUserRequest} />}
        />
        <Route
          exact
          path={PATH_STORY_CATEGORIES}
          element={<AuthRoute Component={StoryCategory} />}
        /> */}
        <Route
          exact
          path={PATH_CUSTOM_NOTIFICATION}
          element={<AuthRoute Component={CustomNotification} />}
        />
        <Route
          exact
          path={PATH_REPORTED}
          element={<AuthRoute Component={ReportedUser} />}
        />
        <Route
          exact
          path={PATH_REPORTED_USERS}
          element={<AuthRoute Component={ReportedUser} />}
        />
        <Route
          exact
          path={PATH_REPORTED_COMMENTS}
          element={<AuthRoute Component={ReportedComments} />}
        />
        <Route
          exact
          path={PATH_REPORTED_POSTS}
          element={<AuthRoute Component={ReportedPosts} />}
        />
        {/* <Route
          exact
          path={PATH_REPORTED_TIME_CAPSULE}
          element={<AuthRoute Component={ReportedTimeCapsule} />}
        />
        <Route
          exact
          path={PATH_VERSION_UPDATE}
          element={<AuthRoute Component={VersionUpdate} />}
        /> */}
      </Routes>
    </>
  );
}
const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    loader: state.loader
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
