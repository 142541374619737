import IconButton from '@mui/material/IconButton';
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './style.scss';

export default function DropDown(props) {
  const { selectedRow = () => {}, id = null } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    selectedRow(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="dropDownline">
        {props.content}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className="midDropdown"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            bgcolor: '#4c4c4c',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              bgcolor: '#4c4c4c'
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#4c4c4c',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {props.list.map((item) => {
          return (
            <div
              key={item?.id}
              className={
                props.list.length === item?.id
                  ? 'dropDowntextLine'
                  : 'dropDowntextNotLine'
              }>
              <MenuItem
                className= 'dropDowntext'
                onClick={() => {
                  if(item.disable){
                  if (props?.data) {
                    if (item?.id === 1) {
                      item.click(props?.id);
                    } else {
                      item.click(props?.itemId);
                    }
                  } else {
                    item.click(props?.id);
                  }}
                }}
              >
                <span className = {item?.disable === false ? 'mouse-disable' : '' }>{item.name}</span>
              </MenuItem>
            </div>
          );
        })}
      </Menu>
    </div>
  );
}
