import React from 'react';
import { Navigate } from 'react-router-dom';
import { URL_PATHNAMES } from './constants/urlPath';

const PublicRoute = ({ Component }) => {
  const { auth_token } = localStorage;
  const { PATH_HOME } = URL_PATHNAMES;
  return auth_token ? <Navigate to={PATH_HOME} /> : <Component />;
};

export default PublicRoute;
