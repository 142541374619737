import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import lang from '../../constants/lang';
import toastMessages from '../../constants/toastMessage';
import { URL_PATHNAMES } from '../../constants/urlPath';
import { logoutUtils } from '../../store/reducers/authentication/apiThunk';
import { PrimaryButton, SecondaryButton } from '../Button';
import PopupModal from '../PopupModal';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import ReportSidebar from './ReportSidebar';
import './style.scss';

const SidebarItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loaction = useLocation();
  const { pathname } = loaction;
  const [active, setActive] = useState(pathname);
  const {
    activeUsers,
    customNotifications,
    reported,
    logout,
  } = lang;

  const {
    PATH_LOGIN,
    PATH_HOME,
    PATH_CUSTOM_NOTIFICATION,
    PATH_REPORTED,
    PATH_REPORTED_USERS,
    PATH_REPORTED_COMMENTS,
    PATH_REPORTED_POSTS,
  } = URL_PATHNAMES;
  const [open, setOpen] = useState(pathname === PATH_REPORTED);
  const { errorToast: { TST_SOMTHING_WRONG = '' } = {} } = toastMessages;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTab = (text) => {
    if (text === PATH_HOME) {
      setActive(text);
      navigate(PATH_HOME);
    } else {
      setActive(text);
      navigate(text);
    }
  };
  useEffect(() => {
    if (
      active === PATH_REPORTED_USERS ||
      active === PATH_REPORTED_COMMENTS ||
      active === PATH_REPORTED_POSTS
    ) {
      setOpen(true);
    }
  }, [open, active]);

  const logoutHandler = () => {
    dispatch(logoutUtils()).then(({ payload }) => {
      const { status = 0 } = payload;
      if (parseInt(status / 100) === 2) {
        localStorage.removeItem('auth_token');
        window.localStorage.clear();
        ToastNotifySuccess('Signed Out Successfully');
        navigate('/');
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
      }
    });
  };  

  return (
    <>
      <div
        className={
          active === PATH_HOME ? 'sidebarItem-active' : 'sidebarItem'
        }
        onClick={() => {
          handleTab(PATH_HOME);
        }}>
        <div className={active === PATH_HOME ? 'sidebar-text' : 'active-sidebar-text'}> {activeUsers}</div>
      </div>
      <div
        className={
          active === PATH_CUSTOM_NOTIFICATION
            ? 'sidebarItem-active'
            : 'sidebarItem'
        }
        onClick={() => {
          handleTab(PATH_CUSTOM_NOTIFICATION);
        }}>
        <div className={active === PATH_CUSTOM_NOTIFICATION ? 'sidebar-text' : 'active-sidebar-text'}>{customNotifications}</div>
      </div>
      <div
        className={
          active === PATH_REPORTED ||
          active === PATH_REPORTED_USERS ||
          active === PATH_REPORTED_COMMENTS ||
          active === PATH_REPORTED_POSTS
            ? 'sidebarItem-active'
            : 'sidebarItem'
        }
        onClick={() => {
          handleTab(PATH_REPORTED);
          setOpen(!open);
        }}>
        <div className={active === PATH_REPORTED ||
          active === PATH_REPORTED_USERS ||
          active === PATH_REPORTED_COMMENTS ||
          active === PATH_REPORTED_POSTS ? 'sidebar-text' : 'active-sidebar-text'}> {reported}</div>
      </div>
      {(active === PATH_REPORTED ||
        active === PATH_REPORTED_USERS ||
        active === PATH_REPORTED_COMMENTS ||
        active === PATH_REPORTED_POSTS ) && <ReportSidebar />}
      <div
        className={active === PATH_LOGIN ? 'sidebarItem-active' : 'sidebarItem'}
        onClick={() => {
          setTooltipOpen(true);
        }}>
        <div> {logout}</div>
      </div>

      <PopupModal
        open={tooltipOpen}
        btnApproveText={'Yes'}
        btnCancelText={'No'}
        onClosed={() => setTooltipOpen(false)}
        onSubmit={logoutHandler}>
        <div className="p-c-2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 500,
              fontsize: '20px',
              lineheight: '32px',
              textAlign: 'center',
              marginTop: '15px'
            }}>
            Sign Out
          </Typography>
          <div className="modal-content">
            <div className="modal-content-heading mt-c-2 modal-popup-heading">
              Are you sure you want to sign out?
            </div>
            <div className='custom-logout-buttons'>
            <PrimaryButton
                variant="outlined"
                onClick={logoutHandler}
                style={{ width : '150px'}}
                className="button">
                Yes
              </PrimaryButton>
            <div
              style={{marginLeft : '10px'}}>
              <SecondaryButton
                variant="outlined"
                onClick={() => setTooltipOpen(false)}
                style={{ width : '150px'}}
                className="custon-button">
                No
              </SecondaryButton>
            </div>
            </div>
          </div>
        </div>
      </PopupModal>
    </>
  );
};

export default SidebarItems;
