import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../network/index';
import END_POINTS from '../../../constants/NetworkConstants';
import { updateLoading } from '../Loader';
const {
  V1: {
    REPORTED_STORY,
    REPORTED_COMMENT,
    REPORTED_USER,
    RESTRICT_USER,
    WARNING_USER,
    WARN_POST_USER,
    WARN_COMMENT_USER
  } = {}
} = END_POINTS;

const fetchReportedStory = createAsyncThunk(
  'reported/story',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(REPORTED_STORY + query);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const fetchReportedComment = createAsyncThunk(
  'reported/comment',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(REPORTED_COMMENT + query);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const fetchReportedUser = createAsyncThunk(
  'reported/user',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(REPORTED_USER + query);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const warningUser = createAsyncThunk(
  'reported/warn-user',
  async (data, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.patch(
        RESTRICT_USER + data.username,
        data.dataToSend
      );
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const warnUser = createAsyncThunk(
  'reported/warning-user',
  async (data, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.post(
        WARNING_USER + data.userId,
        data.dataTosend
      );
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err?.response;
    }
  }
);

const warnCommentUser = createAsyncThunk(
  'reported/warning-user',
  async (data, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.post(
        WARN_COMMENT_USER + data.userId,
        data.dataTosend
      );
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err?.response;
    }
  }
);

const warnPostUser = createAsyncThunk(
  'reported/warning-user',
  async (data, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.post(
        WARN_POST_USER + data.userId,
        data.dataTosend
      );
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err?.response;
    }
  }
);

export {
  fetchReportedStory,
  fetchReportedComment,
  fetchReportedUser,
  warningUser,
  warnUser,
  warnPostUser,
  warnCommentUser,
};
