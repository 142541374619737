/* eslint-disable no-undef */
// /* eslint-disable no-undef */
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { IconButton } from '@mui/material';
import { PrimaryButton } from '../../components/Button';
import { TextInput } from '../../components/TextFields';
import { validationSchemaLogin } from '../../utils/validations';
import CloseEye from '../../components/icon/CloseEye';
import EmailIcon from '../../components/icon/EmailIcon';
import Eye from '../../components/icon/Eye';
import { URL_PATHNAMES } from '../../constants/urlPath';
import { adminLogin } from '../../store/reducers/authentication/apiThunk';
import toastMessages from '../../constants/toastMessage';
import { setTokenToStorage } from '../../utils/common';
import {
  ToastNotifyError,
  ToastNotifySuccess
} from '../../components/Toast/ToastNotify';
import './style.scss';
import lang from '../../constants/lang';
import MainLogo from '../../components/svg/MainLogo';
import { urls } from '../../constants/constants';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { welcomeText, adminPanel, pleaseLogin, forgotPassword, login } = lang;
  const { PATH_HOME } = URL_PATHNAMES;
  const {
    successToast: { TST_LOGIN_SUCCESSFULLY = '' } = {},
    errorToast: { TST_SOMTHING_WRONG = '' } = {}
  } = toastMessages;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const submitHandler = async (values) => {
    let tempVal = values;
    const { email, password } = tempVal;
    tempVal = {
      email: email.toLowerCase(),
      password
    };
    dispatch(adminLogin(tempVal)).then(({ payload }) => {
      const { data: { data: { token = '' }, status = false } = {}, errors = [] } = payload;
      if (status === true) {
        ToastNotifySuccess(TST_LOGIN_SUCCESSFULLY);
        setTokenToStorage(token);
        navigate(PATH_HOME);
      } else {
        if (errors) {
          ToastNotifyError(errors[0]);
        } else {
          ToastNotifyError(TST_SOMTHING_WRONG);
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      isEmail: false,
      email: '',
      password: ''
    },
    validationSchema: validationSchemaLogin,
    onSubmit: submitHandler
  });

  const handleChange = (e, name) => {
    formik.setFieldValue(name, e.target.value.trim());
  };

  const {
    handleSubmit,
    values: { email, password } = {},
    touched: { email: tuc_email = false, password: tuc_password = false } = {},
    errors: { email: err_email, password: err_password } = {}
  } = formik;


  const handleNavigation = () => {
    if (process.env.REACT_APP_ENVIRONMENT)
      window.open(`${urls[process.env.REACT_APP_ENVIRONMENT]}forgot-password`, '_blank', 'noreferrer')
  }

  return (
    <div className="page-login-wrap">
      <div className='mb-c-1'><MainLogo /></div>
      <div className="text-yellow heading-lg"> {welcomeText} </div>
      <div className="text-yellow heading-lg">{adminPanel} </div>
      <div className="text-yellow sub-heading">{pleaseLogin}</div>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="card-data-content flex-box my-c-4">
          <TextInput
            type="text"
            name="email"
            label={'Email*'}
            icon={<EmailIcon />}
            onChange={(e) => handleChange(e, 'email')}
            value={email}
            error={tuc_email && !!err_email}
            helperText={tuc_email && err_email}
          />
          <TextInput
            name="password"
            type={showPassword ? 'text' : 'password'}
            label={'Password*'}
            onChange={(e) => handleChange(e, 'password')}
            value={password}
            icon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <Eye /> : <CloseEye />}
              </IconButton>
            }
            error={tuc_password && !!err_password}
            helperText={tuc_password && err_password}
          />
        </div>
        <div className="link-sm text-yellow text-center" onClick={() => handleNavigation()}>{forgotPassword}</div>
        <div className="mt-c-2 w-full">
          <PrimaryButton fullWidth type="submit" variant="contained">
            {login}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
