import * as yup from 'yup';
import { REGEX } from '../../constants/constants';
import validationMessages from '../../constants/messages';

const {
  MSG_EMAIL_USERNAME_REQ,
  MSG_VALID_EMAIL,
  MSG_EMAIL_REQ,
  MSG_REQUIRED_PASSWORD,
  MSG_EMAIL_LENGTH,
  MSG_NOTIFICATION_TITLE_REQ
} = validationMessages;

const { EMAIL_PATTERN } = REGEX;

const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_USERNAME_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH)
    .required(MSG_EMAIL_REQ),
  password: yup.string().required(MSG_REQUIRED_PASSWORD)
});
const validationSchemeNotificationTitle = yup.object({
  notificationTitle: yup
    .string()
    .required(MSG_NOTIFICATION_TITLE_REQ)
});

export { validationSchemaLogin, validationSchemeNotificationTitle };
