import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DownloadButton } from '../../components/Button';
import SearchBox from '../../components/Search';
import MainTable from '../../components/Table';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import toastMessages from '../../constants/toastMessage';
import { URL_PATHNAMES } from '../../constants/urlPath';
import MainLayout from '../../Layout/MainLayout';
import {
  downloadUserReport,
  fetchUserList,
  searchUserList
} from '../../store/reducers/authentication/apiThunk';
import { downloadFile } from '../../utils/common';
import './style.scss';

export default function ActiveUser() {
  const dispatch = useDispatch();
  const [paginate, setpaginate] = useState({});
  const [pageCount, setPageCount] = useState({});
  const [searchVal, setsearchVal] = useState('');
  const [search, setSearch] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([])
  const [page, setPage] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  const { PATH_ACTIVE_USER } = URL_PATHNAMES;
  const { errorToast: { TST_SOMTHING_WRONG = '' } = {} } = toastMessages;

  const tableHead = [
    {
      id: 'name',
      name: 'Name',
      minWidth: 120
    },
    {
      id: 'email',
      name: 'Email',
      minWidth: 120
    },
    {
      id: 'location',
      name: 'Location',
      minWidth: 120
    },
    {
      id: 'profilePicture',
      name: 'Profile Picture',
      minWidth: 50
    },
    {
      id: 'createdAt',
      name: 'Joining Date',
      minWidth: 50
    },
  ];
  const handleSearch = (e) => {
    setsearchVal(e.target.value);
    setCurrPage(1);
  };

  useEffect(() => {
    if(searchVal === '' || search) {
      setSearch(false);
    const getData = setTimeout(() => {
      const query = `?page=${page}&limit=10`;
      dispatch(fetchUserList(query)).then(({ payload }) => {
        const { status = 0, data: {data: { users = [], count = 1 } = {} }} = payload;
        if (parseInt(status / 100) === 2) {
          setTableData(users);
          setSearchData([])
          setpaginate({
            total_count: count,
            total_pages: Math.ceil(count / 10)
          });
        } else {
          ToastNotifyError(TST_SOMTHING_WRONG);
        }
      });
    }, 350);
    return () => clearTimeout(getData);
  }
    //clearTimeout for cleanup getData function
  }, [page, !searchVal]);

  useEffect(() => {
    if(searchVal !=  ''){
    const getData = setTimeout(() => {
      setSearch(true);
      const query = `?search=${searchVal}&page=${currPage}&limit=10`;
      dispatch(searchUserList(query)).then(({ payload }) => {
        const { status = 0, data: {data: { users = [], count = 1 } = {} }} = payload;
        if (parseInt(status / 100) === 2) {
          setSearchData(users);
          setTableData([])
          setPageCount({
            total_count: count,
            total_pages: Math.ceil(count / 10)
          });
        } else {
          ToastNotifyError(TST_SOMTHING_WRONG);
        }
      });
    }, 350);
    //clearTimeout for cleanup getData function
    return () => clearTimeout(getData);
  }
  }, [currPage, searchVal]);

  const pageFun = (value) => {
    setPage(value);
    setCurrPage(value)
  };

  async function activeUserReportDownload() {
    // const query = `?is_alive=true&search=${searchVal}`;
    dispatch(downloadUserReport()).then(({ payload }) => {
      const { status = 0, data = {} } = payload;
      if (parseInt(status / 100) === 2) {
        downloadFile(data, 'Active Users Report', 'csv');
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
      }
    });
  }

  return (
    <MainLayout tabActive={PATH_ACTIVE_USER}>
      <div className="page-activeuser">
        <div className="search-bar-wrap mb-c-3">
          <div className="search-title">Active Users List</div>
          <div style={{ display: 'flex' }}>
            <SearchBox
              value={searchVal}
              onChange={handleSearch}
              placeholder={'Search by name'}
            />
            <DownloadButton
              sx={{ width: '130px' }}
              onClick={activeUserReportDownload}>
              Download
            </DownloadButton>
          </div>
        </div>
        {tableData ? (
          <MainTable
            tableHead={tableHead}
            columns={searchVal === '' ? tableData : searchData}
            pagination={true}
            paginate={!search ? paginate : pageCount}
            pageFun={pageFun}
          />
        ) : (
          <div className="text-center">No data Found</div>
        )}
      </div>
    </MainLayout>
  );
}
