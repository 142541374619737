const setTokenToStorage = (token) => localStorage.setItem('auth_token', token);

/**
 * Function to downlaod file from blob data
 * @param {*} data
 * @param {*} filename
 * @param {*} type
 * @returns
 */

const downloadFile = (data, filename, type) => {
  const decodedKey = atob(data.data);
    const jsonData = JSON.parse(decodedKey);
    const header = Object.keys(jsonData[0]).join(',');
    const rows = jsonData.map(entry => Object.values(entry).join(','));
    const csvData = [header, ...rows].join('\n');
  const url = window.URL.createObjectURL(
    new Blob([csvData], { type: `application/${type}` })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.${type}`);
  document.body.appendChild(link);
  link.click();
};

export { setTokenToStorage, downloadFile };
