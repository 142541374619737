import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Tooltip, Typography} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import usePagination from '../Pagination';
import DropDown from '../../components/DropDown';
import './style.scss';
import QuestionMark from '../svg/QuestionMark';
import ViewIcon from '../svg/ViewIcon';
import ViewComment from '../svg/ViewComment';
import { Attachment } from '@mui/icons-material';
import PopupModal from '../PopupModal';
import { PrimaryButton, SecondaryButton } from '../Button';
import BlockUserIcon from '../svg/BlockUserIcon';

export default function MainTable(props) {
  const [page, setPage] = useState(1);
  const [attachModal, setAttachModal] = useState();
  const [attachment, setAttachment] = useState();

  const {
    perPage = 10,
    paginate: { total_count = 10, total_pages = 1 } = {},
    columns = [],
    pageFun,
    tableHead,
    pagination = false,
    // acceptRequest,
    // declineRequest,
    selectedRow,
    list = []
  } = props;

  const PER_PAGE = perPage || total_count;
  const count = total_pages || Math.ceil(columns.length / PER_PAGE);
  const _DATA = usePagination(columns, PER_PAGE);
  const renderPagination = () => {
    if (pagination) {
      return (
        <Pagination
          count={count}
          size="large"
          page={page}
          shape="rounded"
          onChange={handleChange}
          className="pagination-block"
        />
      );
    }
    return;
  };

  const handleChange = (e, p) => {
    if (p) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setPage(p);
      _DATA.jump(p);
      pageFun(p);
    }
  };

  useEffect(() => {
    if (page > count) {
      setPage(count);
    }
  }, [count, page]);

  function openProfile(user) {
    if (user)
      // eslint-disable-next-line no-undef
      window.open(process.env.REACT_APP_WEB_PROFILE_URL + user, '_blank');
  }

  function openPost(link) {
      window.open(
        // eslint-disable-next-line no-undef
       link,
        '_blank'
      );
  }
  

  const downloadFile = (fileUrl) => {
    window.open('https://purdriven-dev-media/' + fileUrl);
    setAttachModal(false);
  };

  const attachmentModal = () => {
    return (
      <PopupModal open={attachModal} onClosed={() => setAttachModal(false)}>
        <div className="p-c-2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 500,
              fontsize: '20px',
              lineheight: '32px',
              textAlign: 'center',
              marginTop: '15px'
            }}>
            Download Attachment
          </Typography>
          <div className="modal-content">
            <div
              className="modal-content-heading mt-c-2 modal-popup-heading"
              style={{ width: '328px', textAlign: 'center' }}>
              {attachment ? attachment : 'No Document'}
            </div>
            <div className={attachment ? 'custom-logout-buttons' : 'button-cancel'}>
              {attachment ?
              <PrimaryButton
                variant="outlined"
                onClick={() => downloadFile(attachment)}
                style={{ width: '150px' }}
                className="button">
                Download
              </PrimaryButton> : null}
              <div style={{ marginLeft: '10px' }}>
                <SecondaryButton
                  variant="outlined"
                  onClick={() => setAttachModal(false)}
                  style={{ width: '150px' }}
                  className="custon-button">
                  Cancel
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </PopupModal>
    );
  };

  const selectedRowData = (data) => {
    selectedRow(data);
  };

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          overflow: 'hidden',
          marginBottom: pagination ? '1em' : '7em'
        }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="tableText">
                {tableHead.map((item) => {
                  return (
                    <TableCell
                      key={item.id}
                      style={{
                        minWidth: item.minWidth,
                        textAlign:'center'
                      }}>
                      {item.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="c-word-break">
              {_DATA.currentData().map((row, idx) => {
                return (
                  <TableRow key={row.username + idx} tabIndex={-1}>
                    {tableHead.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} className="cell-text" style={{textAlign : 'center'}}>
                          {column.id === 'profilePicture' ? (
                            <div className="deaduser-action-column curser-pointer" style={{textAlign : 'center'}}>
                              {row?.attachment ? 
                              <div
                                onClick={() => {
                                  setAttachModal(true);
                                  setAttachment(row?.attachment?.path);
                                }}>
                                  {row?.attachment?.path}
                                <Attachment />
                              </div> : null}
                              <div 
                              // onClick={() => {openProfile(row.username)}} 
                              onClick={() => openPost(`https://dev.purdriven.com/profile/${row.id}`)} 
                              style={{textAlign : 'center'}}>
                                <Avatar
                                  alt=""
                                  src={row.profilePictureUrl}
                                />
                              </div>
                            </div>
                          ) : column.id === 'report_action' &&
                            column.name === 'Action' ? (
                            <DropDown
                              content={<MoreVertIcon />}
                              list={list}
                              id={row}
                              selectedRow={selectedRowData}
                            />
                          ) : column.id === 'profilePicture' ||
                            column.id === 'profile' ? (
                            <div
                              className="curser-pointer"
                              onClick={() => openProfile(row.username)} style={{display: 'flex', justifyContent: 'center'}}>
                              <Avatar
                                alt=""
                                src={
                                  row.profilePictureUrl
                                }
                              />
                            </div>
                          ) : column.id === 'name' ? (
                          <div>
                            {`${row.firstName} ${row.lastName}`}
                          </div>
                        )  : column.id === 'reporterUsername' ? (
                          <div>
                            {`${row.User?.firstName} ${row.User?.lastName}`}
                          </div>
                        ) :column.id === 'createdAt' ? (
                          <div>
                            {moment(row.createdAt).format('LL')}
                          </div>
                        ) : column.id === 'postLink' ? (
                            <div className="curser-pointer" style={{textAlign : 'center'}}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => openPost(
                                        row.postLink
                                      )
                                }
                                edge="end">
                                <ViewIcon />
                              </IconButton>
                            </div>
                          ): column.id === 'profileLink' ? (
                            <div className="curser-pointer" style={{textAlign : 'center'}}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => openPost(
                                        row.profileLink
                                      )
                                }
                                edge="end">
                                <BlockUserIcon />
                              </IconButton>
                            </div>
                          ): column.id === 'userProfileLink' ? (
                            <div className="curser-pointer" style={{textAlign : 'center'}}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => openPost(
                                        `https://dev.purdriven.com/profile/${row.id}`
                                      )
                                }
                                edge="end">
                                <ViewIcon />
                              </IconButton>
                            </div>
                          ) : column.id === 'comment' ? (
                            <div className="curser-pointer" style={{textAlign : 'center'}}>
                              <Tooltip title={row.Comment?.description} arrow>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end">
                                  <ViewComment />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : column.id === 'reason' ? (
                            <div className="curser-pointer" style={{textAlign : 'center'}}>
                              <Tooltip title={value} arrow>
                                <IconButton>
                                  <QuestionMark />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : column.id === 'marked_by_username' ? (
                            <div
                              className="curser-pointer"
                              onClick={() => openProfile(row.username)} style={{textAlign : 'center'}}>
                              {value}
                            </div>
                          ) : column.id === 'reportedBy' ? (
                            <div
                            style={{textAlign : 'center'}}>
                              {`${row.reportedByUser?.firstName} ${row.reportedByUser?.lastName}`}
                            </div>
                          ) : column.id === 'reportedOn' ? (
                            <div
                            style={{textAlign : 'center'}}>
                              {moment(row.reportedByUser?.updatedAt).format('LL')}
                            </div>
                          ) : column.id === 'created_at' ||
                            column.id === 'marked_on' ||
                            column.id === 'report_date' ? (
                            <div style={{textAlign : 'center'}}>
                              {value ? moment(value).format('LL') : '-'}
                            </div>
                          ) : column.id === 'attachment' ? (
                            <div></div>
                          ) : (
                            value || '-'
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {renderPagination()}
      {attachmentModal()}
    </>
  );
}
