import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export const TextInput = (props) => {
  return (
    <TextField
      {...props}
      className="custom-field"
      id={props.id}
      label={props.label}
      fullWidth
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.icon}</InputAdornment>
        )
      }}
      sx={{
        height: '42px'
      }}
    />
  );
};
