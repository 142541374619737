export default function QuestionMark() {
return (
<svg width="24" height="24" viewBox="0 0 10 10" fill="#0071BC" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6469_2750)">
<path d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.39991 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216643 4.00021 -0.0968503 5.00555 0.0960758 5.97545C0.289002 6.94536 0.765206 7.83627 1.46447 8.53553C2.16373 9.2348 3.05465 9.711 4.02455 9.90393C4.99446 10.0969 5.99979 9.99784 6.91342 9.6194C7.82705 9.24096 8.60794 8.6001 9.15735 7.77785C9.70676 6.95561 10 5.98891 10 5C9.99857 3.67436 9.47132 2.40342 8.53395 1.46605C7.59658 0.528678 6.32564 0.00143378 5 0V0ZM5 9.16667C4.17591 9.16667 3.37033 8.9223 2.68513 8.46446C1.99992 8.00662 1.46587 7.35587 1.1505 6.59451C0.835139 5.83316 0.752625 4.99538 0.913397 4.18712C1.07417 3.37887 1.47101 2.63644 2.05372 2.05372C2.63644 1.471 3.37887 1.07417 4.18713 0.913395C4.99538 0.752623 5.83316 0.835137 6.59452 1.1505C7.35588 1.46587 8.00662 1.99992 8.46446 2.68512C8.9223 3.37033 9.16667 4.17591 9.16667 5C9.16546 6.1047 8.72608 7.1638 7.94494 7.94494C7.1638 8.72608 6.1047 9.16545 5 9.16667V9.16667Z" fill="#0071BC"/>
<path d="M5.29891 2.10951C5.05863 2.06573 4.81166 2.0753 4.57549 2.13753C4.33932 2.19976 4.11971 2.31314 3.9322 2.46964C3.74469 2.62614 3.59388 2.82194 3.49042 3.04318C3.38696 3.26442 3.33339 3.50569 3.3335 3.74993C3.3335 3.86044 3.37739 3.96642 3.45553 4.04456C3.53367 4.1227 3.63966 4.1666 3.75016 4.1666C3.86067 4.1666 3.96665 4.1227 4.04479 4.04456C4.12293 3.96642 4.16683 3.86044 4.16683 3.74993C4.16672 3.62734 4.19367 3.50623 4.24574 3.39525C4.29781 3.28426 4.37373 3.18613 4.46808 3.10786C4.56244 3.02958 4.6729 2.97309 4.79159 2.94241C4.91028 2.91172 5.03428 2.90761 5.15474 2.93035C5.31934 2.9623 5.47068 3.04259 5.58943 3.16097C5.70818 3.27934 5.78894 3.43043 5.82141 3.59493C5.8542 3.7676 5.83156 3.94621 5.75672 4.10524C5.68189 4.26426 5.55869 4.39556 5.40474 4.48035C5.14981 4.62804 4.93915 4.84139 4.79471 5.09818C4.65027 5.35497 4.57733 5.64579 4.58349 5.94035V6.24993C4.58349 6.36044 4.62739 6.46642 4.70553 6.54456C4.78367 6.6227 4.88965 6.6666 5.00016 6.6666C5.11067 6.6666 5.21665 6.6227 5.29479 6.54456C5.37293 6.46642 5.41683 6.36044 5.41683 6.24993V5.94035C5.4116 5.79535 5.44466 5.65154 5.51269 5.52339C5.58071 5.39523 5.6813 5.28726 5.80433 5.21035C6.1062 5.04454 6.3493 4.78929 6.50019 4.47968C6.65108 4.17008 6.70235 3.82133 6.64694 3.4814C6.59153 3.14147 6.43218 2.82705 6.19078 2.58139C5.94939 2.33572 5.63782 2.17087 5.29891 2.10951Z" fill="#0071BC"/>
<path d="M5.41683 7.49968C5.41683 7.26956 5.23028 7.08301 5.00016 7.08301C4.77005 7.08301 4.5835 7.26956 4.5835 7.49968C4.5835 7.7298 4.77005 7.91635 5.00016 7.91635C5.23028 7.91635 5.41683 7.7298 5.41683 7.49968Z" fill="#0071BC"/>
</g>
<defs>
<clipPath id="clip0_6469_2750">
<rect width="10" height="10" fill="white"/>
</clipPath>
</defs>
</svg>
)
}
