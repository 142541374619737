import React from 'react';
import ReportSidebarItems from './ReportSidebarItems';

const ReportSidebar = () => {
  return (
    <div className="reportSidebarWrap">
      <div className="reportSidebar">
        <ReportSidebarItems />
      </div>
    </div>
  );
};

export default ReportSidebar;
