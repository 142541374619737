import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../network/index';
import END_POINTS from '../../../constants/NetworkConstants';
import { updateLoading } from '../Loader';

const {
  V1: {
    LOGIN,
    USERS,
    CUSTOM_NOTIFICATION,
    DELETE_USER,
    DELETE_STORY,
    DELETE_COMMENT,
    LOGOUT,
    DOWNLOAD_USER_REPORT,
    SEARCH_USER
  } = {}
} = END_POINTS;

const adminLogin = createAsyncThunk(
  'authentication/login',
  async (data, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.post(LOGIN, data);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);
const fetchUserList = createAsyncThunk(
  'authentication/users',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(USERS + query);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const searchUserList = createAsyncThunk(
  'authentication/users',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(SEARCH_USER + query);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

const postCustomNotification = createAsyncThunk(
  'authentication/custom-notification',
  async (query) => {
    try {
      const response = await API.post(CUSTOM_NOTIFICATION, query);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const logoutUtils = createAsyncThunk('authentication/logout', async (data) => {
  try {
    const response = await API.delete(LOGOUT, data);
    return response;
  } catch (err) {
    return err;
  }
});

const terminateUser = createAsyncThunk(
  'authentication/custom-notification',
  async (query) => {
    try {
      const response = await API.delete(DELETE_USER + query);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const deletePost = createAsyncThunk(
  'authentication/custom-notification',
  async (query) => {
    try {
      const response = await API.delete(DELETE_STORY + query);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const deleteComment = createAsyncThunk(
  'authentication/custom-notification',
  async (query) => {
    try {
      const response = await API.delete(DELETE_COMMENT + query);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const downloadUserReport = createAsyncThunk(
  'authentication/users-report',
  async (query, { dispatch }) => {
    dispatch(updateLoading(true));
    try {
      const response = await API.get(DOWNLOAD_USER_REPORT);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  }
);

export {
  adminLogin,
  fetchUserList,
  postCustomNotification,
  terminateUser,
  deletePost,
  deleteComment,
  logoutUtils,
  downloadUserReport,
  searchUserList,
};
