import UserIcon from '../../components/icon/UserIcon';
import Sidebar from '../../components/Sidebar';
import './style.scss';

export default function MainLayout({ tabActive, children }) {
  return (
    <div className="main-layout-wrap">
      <div>
        <Sidebar tabActive={tabActive} />
      </div>
      <div className="main-layout-content">
        <div className="main-header">
          <div className="header-box curser-pointer">
            <UserIcon />
            <div className="username"> {'Admin PurDriven'}</div>
          </div>
        </div>
        <div className="p-c-2">{children}</div>
      </div>
    </div>
  );
}
