import { createSlice } from '@reduxjs/toolkit';

const reportedSlice = createSlice({
  name: 'reported',
  initialState: {
    restrictedTill: []
  },
  reducers: {
    updateWarningUser: (state, action) => {
      state.restrictedTill = action.payload;
    }
  }
});

const { reducer } = reportedSlice;
const selectorWarning = (state) => state.restrictedTill;
const { updateWarningUser } = reportedSlice.actions;
export { updateWarningUser, selectorWarning };

export default reducer;
