import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MainTable from '../../components/Table';
import {
  ToastNotifyError,
  ToastNotifySuccess
} from '../../components/Toast/ToastNotify';
import toastMessages from '../../constants/toastMessage';
import { URL_PATHNAMES } from '../../constants/urlPath';
import MainLayout from '../../Layout/MainLayout';
import {
  fetchReportedUser,
  // warningUser,
  warnUser
} from '../../store/reducers/Reported/apiThunk';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
// import moment from 'moment';
import './style.scss';
import PopupModal from '../../components/PopupModal';
import { TextField } from '@mui/material';
import { terminateUser } from '../../store/reducers/authentication/apiThunk';

export default function ReportedUser() {
  const dispatch = useDispatch();
  const [paginate, setpaginate] = useState({});
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState('');
  const [warningValue, setWarningValue] = useState('');
  const [selection, setSelction] = useState('warning');
  const { PATH_REPORTED } = URL_PATHNAMES;
  const {
    successToast: {
      TST_USER_WARNED_SUCCESSFULLY = '',
      TST_USER_DELETE
    } = {},
    errorToast: { TST_SOMTHING_WRONG = '' } = {}
  } = toastMessages;

  const list = [
    {
      id: 1,
      name: 'Warning',
      click: ({ UserId }) => {
        setId(UserId);
        setOpen(true);
        setWarningValue('');
        setSelction('warning');
      },
      disable: true
    },
    {
      id: 3,
      name: 'Delete User',
      click: ({ UserId }) => {
        setSelction('delete');
        setId(UserId);
        setOpen(true);
      },
      disable: true
    }
  ];

  const tableHead = [
    {
      id: 'reportedBy',
      name: 'Reported by',
      minWidth: 120
    },
    {
      id: 'reportedOn',
      name: 'Reported on ',
      minWidth: 120
    },
    {
      id: 'reporterUsername',
      name: 'Reported user',
      minWidth: 120
    },
    {
      id: 'reason',
      name: 'Reason',
      minWidth: 120
    },
    {
      id: 'report_action',
      name: 'Action',
      minWidth: 35
    }
  ];

 const reportUserList = () => {
    const query = `?page=${page}&limit=10`;
    dispatch(fetchReportedUser(query)).then(({ payload} ) => {
      const { status = 0, data: { data: {reports = [], count = 1 }} = {} } = payload;
      if (parseInt(status / 100) === 2) {
        setTableData(reports);
        setpaginate({
          total_count: count,
          total_pages: Math.ceil(count / 10)
        });
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
      }
    });
  };

  useEffect(() => {
    reportUserList()
  },[page])

  const pageFun = (value) => {
    setPage(value);
  };

  const handleWarningvalue = (value) => {
    setWarningValue(value);
  };

  const onClosed = () =>{ setOpen(false)};

  const warnHandler = () => {
    const data = {
      userId: id,
      dataTosend: {
        reportWarning: warningValue,
      }
    };
    dispatch(warnUser(data)).then(( {payload}) => {
      onClosed()
      reportUserList()
      const { status = ''} = payload;
      if (parseInt(status / 100) === 2) {
      ToastNotifySuccess(TST_USER_WARNED_SUCCESSFULLY)
        setOpen(false);
      } 
    });
  };

  const deleteHandler = () => {
    dispatch(terminateUser(id)).then(( {payload} ) => {
      const { status = 0 } = payload;
      if (parseInt(status / 100) === 2) {
        reportUserList()
        ToastNotifySuccess(TST_USER_DELETE);
        onClosed();
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
        onClosed();
      }
    });
  };

  return (
    <MainLayout tabActive={PATH_REPORTED}>
      <div className="page-activeuser">
        <div className="search-bar-wrap mb-c-3">
          <div className="search-title">Reported Users</div>
        </div>
        <div>{open}</div>
        {tableData.length ? (
          <MainTable
            list={list}
            tableHead={tableHead}
            columns={tableData}
            pagination={true}
            paginate={paginate}
            pageFun={pageFun}
          />
        ) : (
          <div className="text-center">No data Found</div>
        )}
      </div>
      <PopupModal open={open} onClosed={onClosed}>
        <div className="request-modal-content">
          <div className="modal-content-heading">
            {selection === 'warning'
              ? 'Warning'
              : 'Delete User'}
          </div>
          <div className="divider" />
          <div className="modal-content-subheading">
            Are you sure want to
            {selection === 'warning'
              ? ' warn '
              : ' delete '}
            this user?
          </div>
          {selection === 'warning' && (
            <div className="warning-text-field">
              <TextField
                className="text-field"
                inputProps={{
                  maxlength: 256
                }}
                multiline
                rows={2}
                margin="normal"
                variant="outlined"
                placeholder="Warning.."
                onChange={(e) => handleWarningvalue(e.target.value)}
                value={warningValue}
                sx={{
                  input: {
                    fontFamily: 'poppins',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px'
                  }
                }}
              />
            </div>
          )}
          <div className="request-popup-footer">
            <SecondaryButton variant="outlined" size="small" onClick={onClosed}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              variant="contained"
              size="small"
              onClick={
                selection === 'warning'
                  ? warnHandler
                  : deleteHandler
              }>
              Continue
            </PrimaryButton>
          </div>
        </div>
      </PopupModal>
    </MainLayout>
  );
}
