const toastMessages = {
  successToast: {
    TST_LOGIN_SUCCESSFULLY: 'Signed In Successfully',
    TST_CATEGORY_SUCCESSFULLY: 'New Category Added successfully',
    TST_QUESTION_ADD_SUCCESSFULLY: 'New Question Added Successfully',
    TST_QUESTION_UPDATE_SUCCESSFULLY: 'Question Updated Successfully',
    TST_QUESTION_DELETE_SUCCESSFULLY: 'Question Deleted Successfully',
    TST_CATEGORY_UPDATE_SUCCESSFULLY: 'Category Updated Successfully',
    TST_CATEGORY_DELETE_SUCCESSFULLY: 'Category Deleted Successfully',
    TST_USER_RESTRICTED_SUCCESSFULLY: 'User Restricted successfully',
    TST_CUSTOM_NOTIFICATION : 'Notification send successfully to all users',
    TST_USER_WARNED_SUCCESSFULLY : 'Warning sent successfully',
    TST_USER_DELETE : 'User Deleted Successfully',
    TST_POST_DELETE : 'Post Deleted Successfully',
    TST_COMMENT_DELETE : 'Comment Deleted Successfully'
  },

  errorToast: {
    TST_PERMISSION_DENIED: 'Authentication Permission Denied',
    TST_SOMTHING_WRONG: 'Something went wrong, please try after some time',
    TST_NO_USER: 'User Not found'
  }
};
export default toastMessages;
