import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MainTable from '../../components/Table';
import {
  ToastNotifyError,
  ToastNotifySuccess
} from '../../components/Toast/ToastNotify';
import toastMessages from '../../constants/toastMessage';
import { URL_PATHNAMES } from '../../constants/urlPath';
import MainLayout from '../../Layout/MainLayout';
import {
  fetchReportedStory,
  warnPostUser,
} from '../../store/reducers/Reported/apiThunk';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import './style.scss';
import PopupModal from '../../components/PopupModal';
import { TextField } from '@mui/material';
import { deletePost, terminateUser } from '../../store/reducers/authentication/apiThunk';

export default function ReportedPosts() {
  const dispatch = useDispatch();
  const [paginate, setpaginate] = useState({});
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState('');
  const [warningValue, setWarningValue] = useState('');
  const [selection, setSelction] = useState('warning');
  const { PATH_REPORTED } = URL_PATHNAMES;
  const {
    successToast: { TST_USER_WARNED_SUCCESSFULLY = '', TST_POST_DELETE = '', TST_USER_DELETE} = {},
    errorToast: { TST_SOMTHING_WRONG = '' } = {}
  } = toastMessages;

  const list = [
    {
      id: 1,
      name: 'Warning',
      click: ({PostId}) => {
        setId(PostId)
        setSelction('warning')
        setOpen(true);
        setWarningValue('')
      },
      disable: true
    },
    {
      id: 2,
      name: 'Remove Post',
      click: ({PostId}) => {
        setSelction('Remove Post');
        setId(PostId)
        setOpen(true);
      },
      disable: true
    },
    {
      id: 3,
      name: 'Delete User',
      click: ({UserId}) => {
        setSelction('Delete User');
        setId(UserId)
        setOpen(true);
      },
      disable: true
    }

  ];

  const tableHead = [
    {
      id: 'reportedBy',
      name: 'Reported by',
      minWidth: 120
    },
    {
      id: 'reportedOn',
      name: 'Reported on ',
      minWidth: 120
    },
    {
      id: 'reporterUsername',
      name: 'Reported user',
      minWidth: 120
    },
    {
      id: 'profileLink',
      name: 'Reported user Profile',
      minWidth: 120
    },
    {
      id: 'postLink',
      name: 'Post Link',
      minWidth: 100
    },
    {
      id: 'reason',
      name: 'Reason',
      minWidth: 120
    },
    {
      id: 'report_action',
      name: 'Action',
      minWidth: 35
    }
  ];

  const reportPostList = () => {
    const query = `?page=${page}&limit=10`;
    dispatch(fetchReportedStory(query)).then(({ payload }) => {
      const { status = 0, data: {data:{ reports = [], count = 1} } = {} } = payload;
      if (parseInt(status / 100) === 2) {
        setTableData(reports);
        setpaginate({
          total_count: count,
          total_pages: Math.ceil(count / 10)
        });
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
      }
    });
  };

 useEffect(() => {
  reportPostList()
 }, [page])

  const pageFun = (value) => {
    setPage(value);
  };

  const handleWarningvalue = (value) => {
    setWarningValue(value);
  };

  const onClosed = () => setOpen(false);
  const warnHandler = () => {
    const data = {
      userId: id,
      dataTosend: {
        reportWarning: warningValue
      }
    };
    dispatch(warnPostUser(data)).then(({ payload }) => {
      reportPostList()
      onClosed()
      const { status = 0 } = payload;
      if (parseInt(status / 100) === 2) {
        ToastNotifySuccess(TST_USER_WARNED_SUCCESSFULLY);
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
        onClosed();
      }
    });
  };

  const deleteHandler = () => {
    dispatch(deletePost(id)).then(({ payload }) => {
      const { status = 0 } = payload;
      if (parseInt(status / 100) === 2) {
        reportPostList()
        ToastNotifySuccess(TST_POST_DELETE);
        onClosed();
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
        onClosed();
      }
    });
  };

  const deleteUser = () => {
    dispatch(terminateUser(id)).then(( {payload} ) => {
      const { status = 0 } = payload;
      if (parseInt(status / 100) === 2) {
        reportPostList()
        ToastNotifySuccess(TST_USER_DELETE);
        onClosed();
      } else {
        ToastNotifyError(TST_SOMTHING_WRONG);
        onClosed();
      }
    });
  };

  return (
    <MainLayout tabActive={PATH_REPORTED}>
      <div className="page-activeuser">
        <div className="search-bar-wrap mb-c-3">
          <div className="search-title">Reported Posts</div>
        </div>
        <div>{open}</div>
        {tableData.length ? (
          <MainTable
            list={list}
            tableHead={tableHead}
            columns={tableData}
            pagination={true}
            paginate={paginate}
            pageFun={pageFun}
          />
        ) : (
          <div className="text-center">No data Found</div>
        )}
      </div>
      <PopupModal open={open} onClosed={onClosed}>
        <div className="request-modal-content">
          <div className="modal-content-heading">
            {selection === 'warning'
              ? 'Warning'
              : selection === 'Remove Post'
              ? 'Remove Post'
              : 'Delete User'}
          </div>
          <div className="divider" />
          <div className="modal-content-subheading">
            Are you sure want to
            {selection === 'warning'
              ? ' warn  '
              : selection === 'Remove Post'
              ? ' remove '
              : ' delete '}
            this {selection === 'delete' ? ' post ' : ' user '}?
          </div>
          {selection === 'warning' && (
            <div className="warning-text-field">
              <TextField
                className="text-field"
                inputProps={{
                  maxlength: 256
                }}
                multiline
                rows={2}
                margin="normal"
                variant="outlined"
                placeholder="Warning.."
                onChange={(e) => handleWarningvalue(e.target.value)}
                value={warningValue}
                sx={{
                  input: {
                    fontFamily: 'poppins',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px'
                  }
                }}
              />
            </div>
          )}
          <div className="request-popup-footer">
            <SecondaryButton variant="outlined" size="small" onClick={onClosed}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              variant="contained"
              size="small"
              onClick={
                selection === 'warning'
                  ? warnHandler
                  : selection === 'Remove Post'? deleteHandler : deleteUser
              }>
              Continue
            </PrimaryButton>
          </div>
        </div>
      </PopupModal>
    </MainLayout>
  );
}
