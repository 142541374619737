const END_POINTS = {
  V1: {
    LOGIN: '/admin/accounts/login',
    USERS: '/admin/active-user',
    SEARCH_USER: '/admin/user-search',
    // DEATH_REQUEST: '/admin/dead-requests/',
    // ADMIN_CATEGORY: '/admin/categories/',
    // STORY_QUESTIONS: '/admin/story-questions/',
    REPORTED_STORY: '/admin/report/posts',
    REPORTED_COMMENT: '/admin/report/comments',
    REPORTED_USER: '/admin/report/users',
    // RESTRICT_USER: 'admin/user/',
    CUSTOM_NOTIFICATION: '/admin/custom-notification',
    WARNING_USER: '/admin/warning-report-user/',
    WARN_POST_USER: '/admin/warning-report-post/',
    WARN_COMMENT_USER: '/admin/warning-report-comment/',
    LOGOUT: '/accounts/logout/',
    // DASHBOARD: '/admin/dashboard/',
    DELETE_USER: '/admin/user/',
    DELETE_STORY: '/admin/post/',
    // DELETE_TIMECAPSULE : '/story/time-capsule/',
    DELETE_COMMENT: '/admin/comment/',
    DOWNLOAD_USER_REPORT: '/admin/download-user-list/',
    // REPORTED_TIME_CAPSULE : '/admin/reports/time-capsule/',
    // APP_VERSIONS : '/admin/app-version/',
    // ADD_VERSION : '/admin/app-version/'
  }
};

export default END_POINTS;
