import { TextField } from '@mui/material';
import React, { useState } from 'react';
import lang from '../../constants/lang';
import { URL_PATHNAMES } from '../../constants/urlPath';
import MainLayout from '../../Layout/MainLayout';
import { PrimaryButton } from '../../components/Button';
import './style.scss';
import { useDispatch } from 'react-redux';
import {
  ToastNotifyError,
  ToastNotifySuccess
} from '../../components/Toast/ToastNotify';
import toastMessages from '../../constants/toastMessage';
import { postCustomNotification } from '../../store/reducers/authentication/apiThunk';
import { useFormik } from 'formik';
import { validationSchemeNotificationTitle } from '../../utils/validations';

const CustomNotification = () => {
  const dispatch = useDispatch();
  const [notificationValue, setNotificationValue] = useState('');
  const [disableSendButton, setDisableSendButton] = useState(false)
  const {
    errorToast: { TST_SOMETHING_WRONG = '' } = {},
    successToast: { TST_CUSTOM_NOTIFICATION }
  } = toastMessages;

  const { customNotification, notificationTitle, send } = lang;
  const { PATH_CUSTOM_NOTIFICATION } = URL_PATHNAMES;

  const handleNotification = async () => {
    try {
      setDisableSendButton(true);
  
      const query = {
        title: notificationValue,
      };
  
      const { payload } = await dispatch(postCustomNotification(query));
      const { status = 0 } = payload;
  
      if (parseInt(status / 100) === 2) {
        ToastNotifySuccess(TST_CUSTOM_NOTIFICATION);
        formik.resetForm();
        setNotificationValue('');
      } else {
        ToastNotifyError(TST_SOMETHING_WRONG);
      }
    } finally {
      setDisableSendButton(false);
    }
  };
  const handleChange = (e) => {
    formik.setFieldValue('notificationTitle', e.target.value.trim());
  };

  const formik = useFormik({
    initialValues: {
      notificationTitle: ''
    },
    validationSchema: validationSchemeNotificationTitle,
    onSubmit: handleNotification
  });

  return (
    <MainLayout tabActive={PATH_CUSTOM_NOTIFICATION}>
      <div className="search-bar-wrap mb-c-3">
        <div className="search-title">{customNotification}</div>
      </div>
      <div className="custom-notification-container">
        <div className="custom-notification-wrap">
          <form onSubmit={formik.handleSubmit}>
            <div className="notification-field">
              <div className="notification-lable">{notificationTitle}</div>
              <TextField
                className='text-field'
                inputProps={{
                  maxLength: 256
                }}
                multiline
                rows={1}
                margin="normal"
                variant="outlined"
                placeholder={notificationTitle}
                onChange={(e) => {
                  handleChange(e);
                  setNotificationValue(e.target.value);
                }}
                value={notificationValue}
                error={formik.touched.notificationTitle && Boolean(formik.errors.notificationTitle)}
                helperText={formik.touched.notificationTitle && formik.errors.notificationTitle}
                sx={{
                  input: {
                    fontFamily: 'poppins',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px'
                  }
                }}
              />
            </div>
            <div className='send-button'>
              <PrimaryButton
                variant="contained"
                size="small"
                className="button"
                type='submit'
                disabled={disableSendButton}
              >
                {send}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default CustomNotification;
