import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';

const options = {
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  autoClose: 2000,
  draggable: false
};

const optionsInfo = {
  ...options,
  type: toast.TYPE.INFO
};

const optionsSuccess = {
  ...options,
  type: toast.TYPE.SUCCESS
};

const optionsError = {
  ...options,
  type: toast.TYPE.ERROR
};

toast.configure({});

export const ToastNotifyInfo = (message) => {
  toast(message, optionsInfo);
};
export const ToastNotifySuccess = (message) => {
  toast(message, optionsSuccess);
};
export const ToastNotifyError = (message) => {
  toast(message, optionsError);
};
