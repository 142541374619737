const styleMoreModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: '10px',
  borderRadius: '10px'
};

export { styleMoreModal };
