import { createSlice } from '@reduxjs/toolkit';
import { adminLogin, fetchUserList } from './apiThunk';

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    userInfo: {
      status: false
    }
  },
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.userInfo.status = 'failed';
      state.userInfo.error = action.error.message;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(fetchUserList.rejected, (state, action) => {
      state.userInfo.status = 'failed';
      state.userInfo.error = action.error.message;
    });
  }
});

const { reducer } = authenticationSlice;
const selectorAuthentication = (state) => state.authentication;
const { updateUserInfo } = authenticationSlice.actions;
export { updateUserInfo, selectorAuthentication };

export default reducer;
